<template>
  <el-dialog title="文件类型映射选择表"
             :close-on-click-modal="false"
             :visible.sync="fileDialog"
             width="50%"
             append-to-body>
    <div>
      <el-row :gutter="70">
        <el-col :span="11">
          <span style="width: 10%">文件名称:</span>
          <el-input v-model="fileName" style="margin-left: 10px; width: 70%" disabled></el-input>
        </el-col>
<!--        <el-col :span="11">-->
<!--          <span style="width: 10%">文件夹:</span>-->
<!--          <el-input v-model="filesPath" style="margin-left: 10px; width: 70%"></el-input>-->
<!--        </el-col>-->
      </el-row>
      <el-row :gutter="70" style="margin-top: 10px"  v-if="fileType === 'txt'">
        <el-col :span="11">
          <span style="width: 10%">行分隔符:</span>
          <el-radio-group v-model="lineSeparator" size="mini" style="margin-top: 10px;width: 90%" @change="lineSeparatorChange">
              <el-radio label="1" :disabled="item1" border>\t</el-radio>
              <el-radio label="2" :disabled="item2" border>,</el-radio>
              <el-radio label="3" :disabled="item3" border>_</el-radio>
          </el-radio-group>
        </el-col>
        <el-col :span="11">
          <span style="width: 10%">列分隔符:</span>
          <el-radio-group v-model="columnSeparator" size="mini" style="margin-top: 10px;width: 90%" @change="columnSeparatorChange">
            <el-radio label="1" :disabled="item4" border>\t</el-radio>
            <el-radio label="2" :disabled="item5" border>,</el-radio>
            <el-radio label="3" :disabled="item6" border>_</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row  style="margin-top: 10px"  v-if="fileType === 'xls' || fileType === 'xlsx'">
        <el-row>
          <el-col>
            <span style="width: 10%">请选择Excel表里面的Sheet:</span>
            <el-select v-model="sheet" placeholder="请选择"  style="margin-left: 10px; width: 40%">
              <el-option
                  v-for="item in sheetOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span style="width: 10%">输入数据在excel的数据区域，例如D6,F10</span>
        <el-row>
          <el-col :span="11">
            <span style="width: 10%">数据区域左上标:</span>
            <el-input v-model="leftTop" style="margin-left: 10px; width: 50%"  @blur="leftTopBlur($event)"></el-input>
          </el-col>
          <el-col :span="11">
            <span style="width: 10%">数据区域右下标:</span>
            <el-input v-model="rightBottom" style="margin-left: 10px; width: 50%"  @blur="rightBottomBlur($event)"></el-input>
          </el-col>
        </el-row>
      </el-row>

      <div style="margin-top: 20px">
        <span style="margin-left: 15%">数据项名称</span>
        <span style="margin-left: 40%">属性列表</span>
        <div style="margin-left: 1%;margin-top: 1%;">
          <div>
            <el-row :gutter="70">
              <el-col :span="11">
                <div>
<!--                  <el-input-->
<!--                      prefix-icon="el-icon-search"-->
<!--                      @keyup.native="dFSearch"-->
<!--                      ref="dFieldSearch"-->
<!--                      v-model="deviceFieldSearch"-->
<!--                      size="mini"-->
<!--                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;"-->
<!--                      placeholder="输入关键字搜索"/>-->
                  <el-table
                      ref="selectDeviceField3"
                      :data="deviceFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="deviceFieldSelection"
                      @cell-click="cellClick">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectDeviceStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="数据项名称"
                        min-width="70%">
<!--                      <template slot-scope="scope">-->
<!--                        <el-input v-model="scope.row.name" v-if="scope.row.seen"-->
<!--                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>-->
<!--                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>-->
<!--                      </template>-->
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="数据项类型"
                        min-width="70%">
                    </el-table-column>
                  </el-table>
<!--                  <el-button @click="deviceAdd" size="mini" type="primary"-->
<!--                             style="width: 100%;margin-top: 1%;margin-bottom: 1%;" round>添 加-->
<!--                  </el-button>-->
                </div>
              </el-col>
              <el-col :span="11">
                <div>
<!--                  <el-input-->
<!--                      @keyup.native="fileSearch"-->
<!--                      ref="fileInfoSearch"-->
<!--                      v-model="fileInfoSearch"-->
<!--                      size="mini"-->
<!--                      style="width: 100%;margin-top: 1%;margin-bottom: 1%;border-radius:10px;"-->
<!--                      placeholder="输入关键字搜索" prefix-icon="el-icon-search"/>-->
                  <el-table
                      ref="selectFile"
                      :data="fileFieldData"
                      tooltip-effect="dark"
                      height=250
                      border
                      style="width: 100%"
                      :header-cell-class-name="cellClass"
                      @select="fileSelection">
                    <el-table-column
                        type="selection"
                        min-width="30%"
                        :selectable="selectFileStatus">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="字段名"
                        min-width="70%">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.name" v-if="scope.row.seen"
                                  @blur="loseFocus(scope.$index, scope.row)"></el-input>
                        <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <el-row :gutter="50">
          <el-col :span="17">
            <div>
              <el-table
                  :data="selectFileData"
                  height="250"
                  border
                  style="width: 100%">
                <el-table-column
                    prop="selectDeviceField"
                    label="已选择设备字段"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="selectFile"
                    label="已选择文件"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="selectFileField"
                    label="已选择文件字段"
                    width="140">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="danger"
                        @click="handleDeleteFile(scope.$index, scope.row)">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              <el-button @click="selectFileMap" style="width: 100%;height: 5%;padding:5px 10px;font-size: 10px"
                         type="primary" round>确定
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>
    <span slot="footer" class="dialog-footer">
       <el-button @click="fileDialog = false">取 消</el-button>
       <el-button type="primary" @click="fileSure">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "file",
  props:['nowReflectData','workNode','mapData','nowEachTaskData','taskData','allTaskData','fileData'],
  data() {
    return {
      currOperator: '',
      fileType:'',
      lineSeparator:'无',
      columnSeparator:'无',
      fileDialog: false,
      fileName: '',
      filesPath:'',
      sheetFlag: false,//是否显示sheet选项
      sheet: '无',
      leftTop:'',
      rightBottom:'',
      sheetOptions: [],
      deviceFieldSearch: '',
      deviceFieldData: [],
      deviceFieldDataCopy: [],
      fileInfoSearch: '',
      fileFieldData: [],
      fileFieldDataCopy: [],
      selectFileData: [],
      selectFileField: {},
      selectFileSecondField: {},
      selectDeviceField: {},
      selectDeviceSecondField: {},
      nowOneReflectFileName: {},
      oneReflectFileName_temp: {
        FileName: '',
        file: []
      },
      nowOneReflectFile: {},
      oneReflectFile_temp: {
        fileField: '',
        file: '',
        fieldName: '',
        fieldId: '',
        sheet:''
      },
      id:0,
      item1:false,
      item2:false,
      item3:false,
      item4:false,
      item5:false,
      item6:false,
      data:{},
    }
  },
  created() {
    //获取session中的user的id
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
  },
  mounted() {
  },
  methods: {

    open(data) {
      // this.selectFileData=data
      this.fileDialog = true
    },
    close() {
      this.fileDialog = false
    },
    leftTopBlur(event){
      if(this.leftTop!==''&&this.rightBottom!==''&&this.sheet!=='无'){
        this.fileFieldData = []
        for (let i = 0; i < this.fileData.length; i++) {
          if (this.id === this.fileData[i].id) {
            this.fileName = this.fileData[i].fileName
            let data = {
              sheet: this.sheet,
              uid: this.currOperator,
              filesPath: this.filesPath,
              name: this.fileData[i].fileName,
              leftTop:this.leftTop,
              rightBottom:this.rightBottom,
              id:this.allTaskData.linkId,
            }
            console.log(event.target.valueOf())
            this.postRequest("/addDataAccessTask/readExcel", data).then(response => {
              if(JSON.parse(JSON.stringify(response.data))===""){
                this.$message({
                  message: '请输入正确的数据区域！',
                  type: 'success'
                });
              }else {
                for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                  let temp = {
                    name: response.data[i],
                    seen: false,
                    flag: '未选中'
                  }
                  for (let i = 0; i < this.nowReflectData.File.length; i++) {
                    if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
                      temp.flag = '已选中'
                      break;
                    }
                  }
                  this.fileFieldData.push(temp)
                }
              }
            }).then(error => {
              // console.log(error)
            })
            this.fileFieldDataCopy = this.fileFieldData
            setTimeout(() => {
              for (let i = 0; i < this.fileFieldData.length; i++) {
                if (this.fileFieldData[i].flag === '已选中') {
                  this.$refs.selectFile.toggleRowSelection(this.fileFieldData[i], true)
                  this.$options.methods.selectFileStatus(this.fileFieldData[i])
                }
              }
            }, 500)
          }
        }
      }
    },
    rightBottomBlur(event){
      if(this.leftTop!==''&&this.rightBottom!==''&&this.sheet!=='无'){
        this.fileFieldData = []
        for (let i = 0; i < this.fileData.length; i++) {
          if (this.id === this.fileData[i].id) {
            this.fileName = this.fileData[i].fileName
            let data = {
              sheet: this.sheet,
              uid: this.currOperator,
              filesPath: this.filesPath,
              name: this.fileData[i].fileName,
              leftTop:this.leftTop,
              rightBottom:this.rightBottom,
              id:this.allTaskData.linkId,
            }
            console.log(event.target.valueOf())
            this.postRequest("/addDataAccessTask/readExcel", data).then(response => {
              if(JSON.parse(JSON.stringify(response.data))===""){
                this.$message({
                  message: '请输入正确的数据区域！',
                  type: 'success'
                });
              }else {
              for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                let temp = {
                  name: response.data[i],
                  seen: false,
                  flag: '未选中'
                }
                for (let i = 0; i < this.nowReflectData.File.length; i++) {
                  if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
                    temp.flag = '已选中'
                    break;
                  }
                }
                this.fileFieldData.push(temp)
              }
              }
            }).then(error => {
              // console.log(error)
            })
            this.fileFieldDataCopy = this.fileFieldData
            setTimeout(() => {
              for (let i = 0; i < this.fileFieldData.length; i++) {
                if (this.fileFieldData[i].flag === '已选中') {
                  this.$refs.selectFile.toggleRowSelection(this.fileFieldData[i], true)
                  this.$options.methods.selectFileStatus(this.fileFieldData[i])
                }
              }
            }, 500)
          }
        }
      }
    },
    lineSeparatorChange(value){
      this.item4=false;
      this.item5=false;
      this.item6=false;
      if(this.lineSeparator==='1'){
          this.item4 = true;
      }else if(this.lineSeparator==='2'){
          this.item5 = true;
      }else if(this.lineSeparator==='3'){
          this.item6 = true;
      }
      if(this.lineSeparator!=='无'&&this.columnSeparator!=='无'){
        this.fileFieldData = []
        for (let i = 0; i < this.fileData.length; i++) {
          if (this.id === this.fileData[i].id) {
            this.fileName = this.fileData[i].fileName
            let data = {
              uid: this.currOperator,
              filesPath: this.filesPath,
              name: this.fileData[i].fileName,
              lineSeparator:this.lineSeparator,
              columnSeparator:this.columnSeparator,
              id:this.allTaskData.linkId,
            }
            if(this.lineSeparator==='1'){
              data.lineSeparator='\t';
            }else if(this.lineSeparator==='2'){
              data.lineSeparator=','
            }else if(this.lineSeparator==='3'){
              data.lineSeparator='_'
            }
            if(this.columnSeparator==='1'){
              data.columnSeparator='\t'
            }else if(this.columnSeparator==='2'){
              data.columnSeparator=','
            }else if(this.columnSeparator==='3'){
              data.columnSeparator='_'
            }
            console.log("data",data)
            if (this.allTaskData.accessSource === 'txt') {
              this.postRequest("/addDataAccessTask/readTxt", data).then(response => {
                if(JSON.parse(JSON.stringify(response.data))===""){
                  this.$message({
                    message: '请选择适当的分隔符！',
                    type: 'success'
                  });
                }else {
                  for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                    let temp = {
                      name: response.data[i],
                      seen: false,
                      flag: '未选中'
                    }
                    for (let i = 0; i < this.nowReflectData.File.length; i++) {
                      if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
                        temp.flag = '已选中'
                        break;
                      }
                    }
                    this.fileFieldData.push(temp)
                  }
                }
              }).then(error => {
                // console.log(error)
              })
            } else if (this.allTaskData.accessSource === 'word') {
              this.postRequest("/addDataAccessTask/readWord", data).then(response => {
                for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                  let temp = {
                    name: response.data[i],
                    seen: false,
                    flag: '未选中'
                  }
                  for (let i = 0; i < this.nowReflectData.File.length; i++) {
                    if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
                      temp.flag = '已选中'
                      break;
                    }
                  }
                  this.fileFieldData.push(temp)
                }
              }).then(error => {
                // console.log(error)
              })
            }
          }
        }
      }
      console.log("value",value,this.lineSeparator)
    },
    columnSeparatorChange(){
      this.item1=false;
      this.item2=false;
      this.item3=false;
      if(this.columnSeparator==='1'){
          this.item1 = true;
      }else if(this.columnSeparator==='2'){
          this.item2 = true;
      }else if(this.columnSeparator==='3'){
          this.item3 = true;
      }
      if(this.lineSeparator!=='无'&&this.columnSeparator!=='无') {
        this.fileFieldData = []
        for (let i = 0; i < this.fileData.length; i++) {
          if (this.id === this.fileData[i].id) {
            this.fileName = this.fileData[i].fileName
            let data = {
              uid: this.currOperator,
              filesPath: this.filesPath,
              name: this.fileData[i].fileName,
              lineSeparator:this.lineSeparator,
              columnSeparator:this.columnSeparator,
              id:this.allTaskData.linkId,
            }
            if(this.lineSeparator==='1'){
              data.lineSeparator='\t'
            }else if(this.lineSeparator==='2'){
              data.lineSeparator=','
            }else if(this.lineSeparator==='3'){
              data.lineSeparator='_'
            }
            if(this.columnSeparator==='1'){
              data.columnSeparator='\t'
            }else if(this.columnSeparator==='2'){
              data.columnSeparator=','
            }else if(this.columnSeparator==='3'){
              data.columnSeparator='_'
            }
            console.log("data",data)
            if (this.allTaskData.accessSource === 'txt') {
              this.postRequest("/addDataAccessTask/readTxt", data).then(response => {
                if(JSON.parse(JSON.stringify(response.data))===""){
                  this.$message({
                    message: '请选择适当的分隔符！',
                    type: 'success'
                  });
                }else{
                  for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                    let temp = {
                      name: response.data[i],
                      seen: false,
                      flag: '未选中'
                    }
                    for (let i = 0; i < this.nowReflectData.File.length; i++) {
                      if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
                        temp.flag = '已选中'
                        break;
                      }
                    }
                    this.fileFieldData.push(temp)
                  }
                }
              }).then(error => {
                // console.log(error)
              })
            } else if (this.allTaskData.accessSource === 'word') {
              this.postRequest("/addDataAccessTask/readWord", data).then(response => {
                for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                  let temp = {
                    name: response.data[i],
                    seen: false,
                    flag: '未选中'
                  }
                  for (let i = 0; i < this.nowReflectData.File.length; i++) {
                    if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
                      temp.flag = '已选中'
                      break;
                    }
                  }
                  this.fileFieldData.push(temp)
                }
              }).then(error => {
                // console.log(error)
              })
            }
          }
        }
      }
      console.log("value",this.lineSeparator)
    },

    /**
     *加载附属设备字段
     */
    loadDeviceFieldData(id,data) {
      console.log(this.nowReflectData)
      console.log("2222",this.workNode)
      if (this.allTaskData.accessSource === 'txt' || this.allTaskData.accessSource === 'excel' || this.allTaskData.accessSource === 'word') {
        this.id=id;
        this.data=data
        this.fileName = data.name
        let fileArray=this.fileName.split('.')
        this.fileType=fileArray[fileArray.length-1]
        this.filesPath=data.filesPath
        this.deviceFieldData = [];

        this.postRequest("/universaltable/getOtherLeafItem?tableId="+this.workNode.data.levelId+"&id="+this.workNode.data.id).then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            let tmpData = {
              name: response.data.data[i].name,
              type: response.data.data[i].type,
              // fieldId: response.data[i].fieldId,
              fieldId: response.data.data[i].id,
              flag: '未选中',
              seen: false
            }
            if(tmpData.type === undefined || tmpData.type === ""){
              tmpData.type = "未设定"
            }
            console.log("data123:"+tmpData)
            this.deviceFieldData.push(tmpData)
          }
        }).then(error => {
        })
        // let temp = {
        //   coal: this.workNode.parent.parent.parent.parent.data.name,
        //   system: this.workNode.parent.parent.parent.data.name,
        //   subsystem: this.workNode.parent.parent.data.name,
        //   device: this.workNode.parent.data.name,
        //   subDevice: this.workNode.data.name,
        //   subEquipmentId: this.workNode.data.subEquipmentId
        // }
        // this.postRequest("/universaltable/getOtherLeafItem?tableId="+this.workNode.data.levelId+"&id="+this.workNode.data.id).then(response => {
        //   for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
        //     let data = {
        //       name: response.data[i].name,
        //       fieldId: response.data[i].fieldId,
        //       type: response.data[i].type,
        //       flag: '未选中',
        //       seen: false
        //     }
        //     this.deviceFieldData.push(data)
        //   }
        // }).then(error => {
        // })
        this.selectFileData = []
        this.fileFieldData = []

        for (let i = 0; i < this.fileData.length; i++) {
          if (id === this.fileData[i].id) {
            this.fileName = this.fileData[i].fileName
            let data = {
              uid: this.currOperator,
              filesPath:this.filesPath,
              name: this.fileData[i].fileName
            }
            if (this.allTaskData.accessSource === 'excel') {
              this.sheetFlag = true
              this.sheetOptions = []
              this.sheet = ''
              let data = {
                uid: this.currOperator,
                filesPath: this.filesPath,
                name: this.fileData[i].fileName,
                id:this.allTaskData.linkId,
              }
              this.postRequest("/addDataAccessTask/getSheets", data).then(response => {
                for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
                  let temp = {
                    label: response.data[i],
                    value: response.data[i],
                  }
                  this.sheetOptions.push(temp)
                }
              }).then(error => {
                // console.log(error)
              })
            }


            let same = 0
            for (let m = 0; m < this.nowReflectData.File.length; m++) {
              if (this.fileData[i].fileName === this.nowReflectData.File[m].FileName) {
                this.nowOneReflectFileName = JSON.parse(JSON.stringify(this.nowReflectData.File[m]))
                same = 1
                break
              }
            }
            if (same === 0) {
              this.nowOneReflectFileName = JSON.parse(JSON.stringify(this.oneReflectFileName_temp))
              this.nowOneReflectFileName.FileName = this.fileData[i].fileName
              this.nowReflectData.File.push(this.nowOneReflectFileName)
            }
            console.log("his.nowReflectData",this.nowReflectData)
            for (let m = 0; m < this.nowReflectData.File.length; m++) {
              if (this.fileData[i].fileName === this.nowReflectData.File[m].FileName) {
                for (let n = 0; n < this.nowReflectData.File[m].file.length; n++) {
                  if (this.fileData[i].fileName === this.nowReflectData.File[m].file[n].file) {
                    let data = {
                      selectFile: this.nowReflectData.File[m].file[n].file,
                      selectFileField: this.nowReflectData.File[m].file[n].fileField,
                      selectDeviceField: this.nowReflectData.File[m].file[n].fieldName,
                      fieldId: this.nowReflectData.File[m].file[n].fieldId,
                    }
                    this.selectFileData.push(data)
                  }
                }
                break;
              }
            }

          }
        }
        setTimeout(() => {
          for (let i = 0; i < this.mapData.length; i++) {
            for (let j = 0; j < this.fileFieldData.length; j++) {
              if (this.mapData[i].tableData === this.fileFieldData[j].name&&this.mapData[i].tableName===this.fileName) {
                this.fileFieldData[j].flag = '已选中2'
              }
            }
          }
          for (let i = 0; i < this.mapData.length; i++) {
            for (let j = 0; j < this.deviceFieldData.length; j++) {
              if (this.mapData[i].deviceData === this.deviceFieldData[j].name) {
                this.deviceFieldData[j].flag = '已选中2'
              }
            }
          }
          for (let i = 0; i < this.fileFieldData.length; i++) {
            if (this.fileFieldData[i].flag === '已选中2') {
              this.$refs.selectFile.toggleRowSelection(this.fileFieldData[i], true)
            }
            this.$options.methods.selectFileStatus(this.fileFieldData[i])
          }
          for (let j = 0; j < this.deviceFieldData.length; j++) {
            if (this.deviceFieldData[j].flag === '已选中2') {
              this.$refs.selectDeviceField3.toggleRowSelection(this.deviceFieldData[j], true)
            }
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
          }
        }, 500)
      }
      this.fileDialog=true
      if (this.allTaskData.accessSource === 'word') {
        this.lineSeparator = '1';
        this.columnSeparator='1'
        this.lineSeparatorChange(null);
      }


    },

    handleDeleteFile(index, row) {
      //删除表内映射信息
      this.selectFileData.splice(index, 1)
      //删除总映射信息
      for (let i = 0; i < this.mapData.length; i++) {
        if (row.selectFile === this.mapData[i].tableName && row.selectFileField === this.mapData[i].tableData && row.selectDeviceField === this.mapData[i].deviceData && row.fieldId === this.mapData[i].fieldId) {
          console.log("*****")
          this.mapData.splice(i, 1);
          break;
        }
      }
      //恢复表内附属设备和数据源字段的状态
      for (let i = 0; i < this.deviceFieldData.length; i++) {
        if (row.selectDeviceField === this.deviceFieldData[i].name) {
          this.deviceFieldData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectDeviceField3.toggleRowSelection(this.deviceFieldData[i], false)
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
          }, 500)
        }
      }
      for (let i = 0; i < this.fileFieldData.length; i++) {
        if (row.selectFileField === this.fileFieldData[i].name) {
          this.fileFieldData[i].flag = '未选中'
          setTimeout(() => {
            this.$refs.selectFile.toggleRowSelection(this.fileFieldData[i], false)
            this.$options.methods.selectFileStatus(this.fileFieldData[i])
          }, 500)
        }
      }

      //删除映射里面的
      for (let i = 0; i < this.nowReflectData.File.length; i++) {
        if (this.fileName === this.nowReflectData.File[i].FileName) {
          this.nowOneReflectFileName = this.nowReflectData.File[i]
        }
      }
      console.log(row)
      console.log("之前", this.nowOneReflectFileName)
      if (this.fileName === this.nowOneReflectFileName.fileName) {
        for (let i = 0; i < this.nowOneReflectFileName.file.length; i++) {
          if (this.nowOneReflectFileName.file[i].file === row.file && this.nowOneReflectFileName.file[i].fileField=== row.selectFileField && this.nowOneReflectFileName.file[i].fieldName === row.selectDeviceField && this.nowOneReflectFileName.file[i].fieldId === row.fieldId) {
            this.nowOneReflectFileName.file.splice(i, 1);
            break;
          }
        }
      }
      console.log("之后", this.nowOneReflectFileName)
      for (let i = 0; i < this.nowReflectData.File.length; i++) {
        if (this.fileName === this.nowReflectData.File[i].fileName) {
          this.nowReflectData.File[i] = JSON.parse(JSON.stringify(this.nowOneReflectFileName))
        }
      }
      this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))


      for (let i = 0; i < this.taskData.length; i++) {
        if (this.taskData[i].subEquipmentId === this.nowEachTaskData.subEquipmentId) {
          this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
        }
      }
      this.allTaskData.taskData = this.taskData
    },
    selectFileMap() {
      if (this.selectFileField.name === undefined || this.selectDeviceField.name === undefined) {
        this.$message({
          message: '映射关系已经添加或映射内容是空的！',
          type: 'warning'
        });
      } else {
        for (let i = 0; i < this.nowReflectData.File.length; i++) {
          if (this.fileName === this.nowReflectData.File[i].FileName) {
            this.nowOneReflectFileName= this.nowReflectData.File[i]
          }
        }
        if (this.fileName === this.nowOneReflectFileName.FileName) {

          this.nowOneReflectFile = JSON.parse(JSON.stringify(this.oneReflectFile_temp))
          this.nowOneReflectFile.file = this.fileName
          this.nowOneReflectFile.fileField = this.selectFileField.name
          this.nowOneReflectFile.fieldName = this.selectDeviceField.name
          this.nowOneReflectFile.fieldId = this.selectDeviceField.fieldId
          this.nowOneReflectFile.sheet= this.sheet
          this.nowOneReflectFileName.file.push(this.nowOneReflectFile)
          //显示一个任务的映射信息
          let data1 = {
            tableData: this.selectFileField.name,
            deviceData: this.selectDeviceField.name,
            tableName: this.fileName,
            fieldId: this.nowOneReflectFile.fieldId
          }
          this.mapData.push(data1)
          let data2 = {
            selectFile: this.fileName,
            selectFileField: this.selectFileField.name,
            selectDeviceField: this.selectDeviceField.name,
            fieldId: this.nowOneReflectFile.fieldId
          }
          this.selectFileData.push(data2)
        }

        for (let i = 0; i < this.fileFieldData.length; i++) {
          if (this.selectFileField.name === this.fileFieldData[i].name) {
            this.fileFieldData[i].flag = '已选中2'
            this.$options.methods.selectFileStatus(this.fileFieldData[i])
            break;
          }
        }
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceField.fieldId === this.deviceFieldData[i].fieldId) {
            this.deviceFieldData[i].flag = '已选中2'
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[i])
            break;
          }
        }
        this.selectFileField = {}
        this.selectDeviceField = {}
        this.selectFileSecondField = {}
        this.selectDeviceSecondField = {}

        for (let i = 0; i < this.nowReflectData.File.length; i++) {
          if (this.fileName === this.nowReflectData.File[i].FileName) {
            this.nowReflectData.File[i] = JSON.parse(JSON.stringify(this.nowOneReflectFileName))
          }
        }
        this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))


        for (let i = 0; i < this.taskData.length; i++) {
          if (this.taskData[i].subEquipmentId === this.nowEachTaskData.subEquipmentId) {
            this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
          }
        }
        this.allTaskData.taskData = this.taskData
      }
    },
    fileSure() {
      this.$emit("fileSure",this.selectFileData)
      this.fileDialog = false
    },
    loseFocus(index, row) {
      // debugger
      row.seen = false;
    },
    cellClick(row, column) {
      // debugger
      row.seen = true;
    },
    cellClass(row) {
      console.log("***", row)
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
    // dFSearch() {
    //   this.deviceFieldSearch = this.$refs.dFieldSearch.value;
    //   this.deviceFieldData = this.deviceFieldDataCopy;
    //   if (this.deviceFieldSearch === '') {
    //     this.deviceFieldData = this.deviceFieldDataCopy;
    //   } else {
    //     let data = []
    //     this.deviceFieldData.map(msg => {
    //       //拿当前json的id、name、time去分别跟输入的值进行比较
    //       //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
    //       if (msg.name.indexOf(this.deviceFieldSearch) != -1) {
    //         //然后把当前json添加到list数组中
    //         data.push(msg);
    //       }
    //     })
    //     this.deviceFieldData = data
    //   }
    // },
    deviceFieldSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      this.selectDeviceField = row
      if (this.selectDeviceSecondField.fieldId === undefined) {
        this.selectDeviceSecondField = this.selectDeviceField
      } else {
        for (let i = 0; i < this.deviceFieldData.length; i++) {
          if (this.selectDeviceSecondField.fieldId === this.deviceFieldData[i].fieldId) {
            this.$refs.selectDeviceField3.toggleRowSelection(this.deviceFieldData[i], false)
            this.deviceFieldData[i].flag = '未选中'
            break;
          }
        }
        this.selectDeviceSecondField = this.selectDeviceField
      }
      if (row.flag === '未选中' && this.selectDeviceSecondField === this.selectDeviceField) {
        this.selectDeviceSecondField = {}
        this.selectDeviceField = {}
      }
    },
    selectDeviceStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },

    // fileSearch() {
    //   this.fileInfoSearch = this.$refs.fileInfoSearch.value;
    //   this.fileFieldeData = this.fileFieldDataCopy;
    //   if (this.fileInfoSearch === '') {
    //     this.fileFieldeData = this.fileFieldDataCopy;
    //   } else {
    //     let data = []
    //     this.fileFieldeData.map(msg => {
    //       //拿当前json的id、name、time去分别跟输入的值进行比较
    //       //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
    //       if (msg.name.indexOf(this.fileInfoSearch) != -1) {
    //         //然后把当前json添加到list数组中
    //         data.push(msg);
    //       }
    //     })
    //     this.fileFieldeData = data
    //   }
    // },
    fileSelection(selection, row) {
      if (row.flag === '已选中') {
        row.flag = '未选中'
      } else {
        row.flag = '已选中'
      }
      console.log(row)
      this.selectFileField = row
      if (this.selectFileSecondField.name === undefined) {
        this.selectFileSecondField = this.selectFileField
      } else {
        for (let i = 0; i < this.fileFieldData.length; i++) {
          if (this.selectFileSecondField.name === this.fileFieldData[i].name) {
            this.$refs.selectFile.toggleRowSelection(this.fileFieldData[i], false)
            this.fileFieldData[i].flag = '未选中'
            break;
          }
        }
        this.selectFileSecondField = this.selectFileField
      }
      if (row.flag === '未选中' && this.selectFileSecondField === this.selectFileField) {
        this.selectFileSecondField = {}
        this.selectFileField = {}
      }
    },
    selectFileStatus(row) {
      if (row.flag === '已选中2') {
        return false
      }
      return true
    },

    // deviceAdd() {
    //   let data = {
    //     name: '',
    //     seen: false
    //   }
    //   this.deviceFieldData.push(data)
    // },
    // sheetChange() {
    //   this.fileFieldData = []
    //   let data = {
    //     name: this.fileName,
    //     sheet: this.sheet
    //   }
    //   this.postRequest("/addDataAccessTask/readExcel", data).then(response => {
    //     for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
    //       let temp = {
    //         name: response.data[i],
    //         seen: false,
    //         flag: '未选中'
    //       }
    //       for (let i = 0; i < this.nowReflectData.File.length; i++) {
    //         if (this.fileName === this.nowReflectData.File[i].file && temp.name === this.nowReflectData.File[i].fileField) {
    //           temp.flag = '已选中'
    //           break;
    //         }
    //       }
    //       this.fileFieldData.push(temp)
    //     }
    //   }).then(error => {
    //     // console.log(error)
    //   })
    //   this.fileFieldDataCopy = this.fileFieldData
    //   setTimeout(() => {
    //     for (let i = 0; i < this.fileFieldData.length; i++) {
    //       if (this.fileFieldData[i].flag === '已选中') {
    //         this.$refs.selectFile.toggleRowSelection(this.fileFieldData[i], true)
    //         this.$options.methods.selectFileStatus(this.fileFieldData[i])
    //       }
    //     }
    //   }, 500)
    // },
  },
}
</script>

<style scoped>
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '操作';
  text-align: center;
  line-height: 50px;
}
</style>
